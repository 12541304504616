<template>
    <div class="the-booking-form">
        <div class="the-booking-form__wrapper">
            <div
                ref="locationField"
                class="the-booking-form__field"
            >
                <div class="the-booking-form__input the-booking-form__input--location">
                    <label for="location">
                        {{ $t('booking-form.location-label') }}
                    </label>
                    <input
                        id="location"
                        type="text"
                        :value="locationValue"
                        :placeholder="$t('booking-form.location-placeholder')"
                        readonly
                        @click="toggleDropdown('location')"
                    >
                </div>

                <div
                    v-if="dropdownActive('location')"
                    ref="locationDropdown"
                    class="the-booking-form__dropdown"
                >
                    <a
                        class="the-booking-form__dropdown-close"
                        @click="toggleDropdown('location')"
                    >
                        <BaseIcon icon="cross" />
                    </a>
                    <ThumbnailList
                        :items="bookingFormItems"
                        :active-item="formData.location"
                        :pending="pending"
                        @click:item="handleClickHotel"
                    >
                        <template
                            v-for="(item, index) in bookingFormItems"
                            :key="`item-${index}`"
                            #[`item-${index}`]
                        >
                            <BaseDotLink
                                :active="formData.location === item"
                            >
                                <template v-if="item.__typename === 'properties_property_Entry'">
                                    <DashedText>
                                        <template #left>
                                            {{ item.city?.[0]?.title }}
                                        </template>
                                        <template #right>
                                            <template v-if="item.propertyPhase === 'comingSoon'">
                                                {{ $t('coming-soon') }}
                                            </template>
                                            <template v-else>
                                                {{ item.area }}
                                            </template>
                                        </template>
                                    </DashedText>
                                </template>

                                <template v-else-if="item.__typename === 'cities_city_Entry'">
                                    <DashedText>
                                        <template #left>
                                            {{ item.title }}
                                        </template>
                                        <template #right>
                                            {{ $t('booking-form.cities-all') }}
                                        </template>
                                    </DashedText>
                                </template>
                            </BaseDotLink>
                        </template>
                    </ThumbnailList>

                    <BaseButton
                        class="base-button--secondary base-button--wide"
                        @click="toggleDropdown('date')"
                    >
                        {{ $t('booking-overlay.step-1.prompt') }}
                    </BaseButton>
                </div>
            </div>

            <div
                ref="dateField"
                class="the-booking-form__field"
            >
                <div class="the-booking-form__input">
                    <label for="date">
                        {{ $t('booking-form.date-label') }}
                    </label>
                    <input
                        id="date"
                        type="text"
                        :placeholder="$t('booking-form.date-placeholder')"
                        :value="dateValue"
                        readonly
                        @click="toggleDropdown('date')"
                    >
                </div>
                <div
                    v-if="dropdownActive('date')"
                    ref="dateDropdown"
                    class="
                    the-booking-form__dropdown"
                >
                    <a
                        class="the-booking-form__dropdown-close"
                        @click="toggleDropdown('date')"
                    >
                        <BaseIcon icon="cross" />
                    </a>
                    <BaseDatePicker
                        v-model="formData.date"
                        :active-property="selectedLocation"
                    />

                    <BaseButton
                        class="base-button--secondary base-button--wide"
                        @click="toggleDropdown('guests')"
                    >
                        {{ $t('booking-overlay.step-2.prompt') }}
                    </BaseButton>
                </div>
            </div>

            <div
                ref="guestsField"
                class="the-booking-form__field the-booking-form__field--split-mobile"
            >
                <div class="the-booking-form__input">
                    <label for="guests">
                        {{ $t('booking-form.guests-label') }}
                    </label>
                    <input
                        id="guests"
                        type="text"
                        :value="guestsValue"
                        :placeholder="$t('booking-form.guests-placeholder')"
                        readonly
                        @click="toggleDropdown('guests')"
                    >
                </div>
                <div
                    v-if="dropdownActive('guests')"
                    ref="guestsDropdown"
                    class="the-booking-form__dropdown"
                >
                    <a
                        class="the-booking-form__dropdown-close"
                        @click="toggleDropdown('guests')"
                    >
                        <BaseIcon icon="cross" />
                    </a>

                    <BasePersonFilter v-model="formData.guests" />


                    <BaseButton
                        class="base-button--secondary base-button--wide"
                        @click="toggleDropdown('guests'); $refs.codeInput.focus()"
                    >
                        {{ $t('booking-overlay.step-3.prompt') }}
                    </BaseButton>
                </div>
            </div>

            <div
                ref="codeField"
                class="the-booking-form__field the-booking-form__field--split-mobile"
            >
                <div class="the-booking-form__input">
                    <label for="code">
                        {{ $t('booking-form.promo-code-label') }}
                    </label>
                    <input
                        id="code"
                        ref="codeInput"
                        v-model="formData.code"
                        type="text"
                        :placeholder="$t('booking-form.promo-code-placeholder')"
                    >
                </div>
            </div>

            <BaseButton
                class="the-booking-form__button base-button--secondary base-button--large"
                :disabled="buttonLoading"
                @click="handleBookButtonClick"
            >
                <template v-if="!buttonLoading">
                    {{ $t('check-availability') }}
                </template>
                <template v-else>
                    Loading...
                </template>
            </BaseButton>
        </div>

        <div
            v-if="bookerActive && !(selectedLocation?.mewsId || mewsIds?.length) "
            class="the-booking-form__text the-booking-form__text--error"
        >
            <template v-if="!selectedLocation">
                Please select a location
            </template>
            <template v-else>
                This location is not bookable, please select another location
            </template>
        </div>
        <div
            v-else-if="!!$slots.tagline"
            class="the-booking-form__text"
        >
            <slot name="tagline" />
        </div>

        <MewsBookingEngine
            v-if="bookerActive && (selectedLocation?.mewsId || mewsIds?.length)"
            :form-data="formData"
            :mews-id="selectedLocation?.mewsId"
            :mews-ids="mewsIds"
        />
    </div>
</template>

<script setup>
import PropertiesQuery from '~/graphql/queries/properties.graphql';
import { onClickOutside } from '@vueuse/core';
import { format, isSameMonth } from 'date-fns';

const bookerActive = ref(false);
const buttonLoading = ref(false);

watch(bookerActive, () => {
    if (bookerActive.value) {
        buttonLoading.value = true;
        setTimeout(() => {
            buttonLoading.value = false;
        }, 1000);
    }
});

// Always load the Mews Distributor script
useHead({
    script: [
        {
            src: 'https://api.mews.com/distributor/distributor.min.js'
        },
    ]
});

const { variables } = useCraftGraphql();

// Ignore the URI in this case
delete variables.uri;

const { pending, data } = await useLazyAsyncQuery({
    query: PropertiesQuery,
    variables
});

const properties = computed(() => {
    return data?.value?.entries.filter((property) => {
        return property.propertyPhase !== 'inDevelopment';
    });
});

const cities = computed(() => {
    return data?.value?.citiesEntries.filter((city) => {
        return city.showInBookingForm === true;
    });
});


const bookingFormItems = computed(() => {

    // returns sorted list of properties and cities:
    // Sort based on city.title and property.city.title
    // 1. properties
    // 2. cities
    return cities.value
        ?.concat(properties.value)
        ?.sort((a, b) => {
            const aTitle = a.city?.[0]?.title || a.title;
            const bTitle = b.city?.[0]?.title || b.title;

            return aTitle.localeCompare(bTitle);
        });
});

const selectedLocation = ref(null);

const mewsIds = computed(() => {
    if (selectedLocation.value?.__typename === 'cities_city_Entry') {
        return properties.value
            .filter((property) => {
                if (!property.mewsId) {
                    return false;
                }

                return property.city?.[0]?.title === selectedLocation?.value?.title;
            })
            .map((property) => property.mewsId);
    }

    return [];
});

const shortCity = computed(() => {
    if (!selectedLocation.value) {
        return '';
    }

    if (selectedLocation.value?.__typename === 'cities_city_Entry') {
        return selectedLocation.value.title.slice(0,3);
    }

    if (!selectedLocation.value?.city) {
        return '';
    }

    return toValue(selectedLocation)?.city[0].title.slice(0,3);
});

const formData = ref({
    location: {},
    date: {
        start: null,
        end: null
    },
    guests: {
        adults: 1,
        kids: 0
    },
    code: null
});


watch(formData, () => {
    bookerActive.value = false;
}, {immediate: true, deep: true});

const handleBookButtonClick = () => {
    bookerActive.value = false;

    setTimeout(() => {
        bookerActive.value = true;
    }, 50);
};

const locationValue = computed(() => {
    let value = `${shortCity.value} – ${t('booking-form.cities-all')}`;

    if (selectedLocation?.value?.__typename === 'properties_property_Entry') {
        value = `${shortCity.value} – ${selectedLocation?.value?.area}`;
    }

    return selectedLocation.value ? value.toString().toUpperCase() : '';
});

const dateValue = computed(() => {
    if (!formData.value.date?.start && !formData.value.date?.end) {
        return '';
    }

    let start = format(new Date(formData.value.date.start), 'dd');
    const end = format(new Date(formData.value.date.end), 'dd MMM yyyy');

    if (!isSameMonth(new Date(formData.value.date.start), new Date(formData.value.date.end))) {
        start = format(new Date(formData.value.date.start), 'dd MMM yyyy');
    }

    return `${start} – ${end}`;
});

const { t } = useI18n();

const guestsValue = computed(() => {
    const adults = formData.value.guests?.adults;
    const kids = formData.value.guests?.kids;


    if (adults && kids) {
        return `${adults} ${t('booking-form.adults', adults)}, ${kids} ${t('booking-form.kids', kids)}`;
    } else if (adults) {
        return `${adults} ${t('booking-form.adults', adults)}`;
    } else if (kids) {
        return `${kids} ${t('booking-form.kids', kids)}`;
    }

    return '';
});

function handleClickHotel(hotel) {
    if (selectedLocation.value === hotel) {
        selectedLocation.value = null;
        formData.value.location = {};
    } else {
        selectedLocation.value = hotel;
        formData.value.location = hotel;
        toggleDropdown('date');
        // handleClickStep(2);
    }
}

const dropdown = ref({
    location: false,
    date: false,
    guests: false
});

const locationField = ref(null);
const dateField = ref(null);
const guestsField = ref(null);
const codeField = ref(null);

const locationDropdown = ref(null);
const dateDropdown = ref(null);
const guestsDropdown = ref(null);

function scrollToField(field) {
    if (window) {
        setTimeout(() => {
            let top = 0;
            const offset = 13;

            if (field === 'location') {
                if (!locationField.value) {
                    top = 0;
                }

                top = window.scrollY +
                    (window.scrollY +
                    locationField.value.getBoundingClientRect().top +
                    locationField.value.getBoundingClientRect().height)
                    - (
                        window.scrollY +
                        locationDropdown.value.getBoundingClientRect().top
                    ) + offset;
            } else if (field === 'date') {
                if (!dateField.value) {
                    top = 0;
                }

                top = window.scrollY +
                    (window.scrollY +
                    dateField.value.getBoundingClientRect().top +
                    dateField.value.getBoundingClientRect().height)
                    - (
                        window.scrollY +
                        dateDropdown.value.getBoundingClientRect().top
                    ) + offset;
            } else if (field === 'guests') {
                if (!guestsField.value) {
                    top = 0;
                }

                top = window.scrollY +
                    (window.scrollY +
                    guestsField.value.getBoundingClientRect().top +
                    guestsField.value.getBoundingClientRect().height)
                    - (
                        window.scrollY +
                        guestsDropdown.value.getBoundingClientRect().top
                    ) + offset;
            } else if (field === 'code') {
                if (!codeField.value) {
                    top = 0;
                }

                elementTopWithinViewport = codeField.value.getBoundingClientRect().top;

                window.scroll({
                    top: window.scrollY + elementTopWithinViewport,
                    behavior: 'smooth',
                });

                return;
            }

            window.scroll({
                top,
                behavior: 'smooth',
            });
        }, 200);
    }
}

const toggleDropdown = (key) => {
    if (dropdown.value[key] === true) {
        dropdown.value[key] = false;
        return;
    }

    scrollToField(key);

    Object.keys(dropdown.value).forEach((dropdownKey) => {
        dropdown.value[dropdownKey] = false;
    });

    dropdown.value[key] = !dropdown.value[key];
};

// Close dropdown when clicking outside
onClickOutside(locationDropdown, () => toggleDropdown('location'));
onClickOutside(dateDropdown, () => toggleDropdown('date'));
onClickOutside(guestsDropdown, () => toggleDropdown('guests'));

const dropdownActive = (key) => {
    return dropdown.value[key];
};
</script>

<style lang="less">
.the-booking-form {
    .base-grid(var(--grid-maxWidth-page), false);
    position: relative;
    z-index: 99;
    top: calc(var(--banner-height) * -1);
}

.the-booking-form__wrapper {
    grid-column: first/right-side-gutter;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-xs);
    align-items: center;
    margin-top: -2.15rem;

    @media @q-md-min {
        grid-template-columns: repeat(4, 1fr) auto;
        margin-top: -2.325rem;
        gap: 0;
        background: #fff;
        border-radius: 4rem;
        padding-left: 1.25rem;
    }
}

.the-booking-form__input {
    background: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 4rem;

    padding-block: .75rem;

    label {
        margin-bottom: .25rem;
        margin-inline: 1.5rem;
        width: calc(100% - 4rem);
        white-space: nowrap;

        font-size: .875rem;
    }

    input {
        appearance: none;
        border: 0;
        font-size: 1rem;
        margin-inline: 1.5rem;
        padding: 0;
        width: calc(100% - 4rem);

        &:focus {
            outline: none;
        }
    }

    &:has(input:focus) {
        box-shadow: 0 0 0 3px #73808F33;
    }

    @media @q-md-min {
        border-radius: 0;
        padding-block: 1.05rem;
        background: transparent;
        height: 4.875rem;

        label {
            margin-bottom: .625rem;
        }

        input {
            font-size: 1.125rem;
        }

        label, input {
            margin-inline: .625rem;
            width: calc(100% - 1.3rem);
        }

        &:has(input:focus) {
            box-shadow: none;
        }
    }

    & + & {
        border-left: 1px solid var(--color-gray-300);
    }
}

.the-booking-form__field {
    position: relative;
    width: 100%;
    grid-column: 1/-1;

    @media @q-md-min {
        grid-column: auto;

        &:not(:first-child) {
            border-left: 1px solid var(--color-cream);
        }
    }
}

.the-booking-form__field--split-mobile {
    grid-column: 1;

    & + & {
        grid-column: 2;
    }

    @media @q-md-min {
        grid-column: auto;

        & + & {
            grid-column: auto;
        }
    }
}

.the-booking-form__dropdown {
    background: #fff;
    z-index: 99;

    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    padding: var(--grid-gutter-width);

    .base-dot-link {
        display: flex;
    }

    @media @q-md-min {
        position: absolute;
        left: .625rem;
        right: auto;
        bottom: auto;
        width: auto;
        min-width: 25rem;
        padding: .5rem 1rem;

        .base-button--secondary {
            display: none;
        }
    }
}

.the-booking-form__dropdown-close {
    font-size: 1rem;
    margin-left: auto;
    cursor: pointer;

    .dn-icon {
        width: 1.25rem;
        height: 1rem;

        svg {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    @media @q-md-min {
        display: none;
    }
}

.base-button.the-booking-form__button {
    width: 100%;
    text-align: center;
    justify-content: center;
    font-size: 1rem;
    flex: 0 0 auto;
    background: var(--color-secondary);
    padding: 1rem 1.25rem;
    border-radius: 2rem;
    cursor: pointer;

    grid-column: 1/-1;

    @media @q-md-min {
        width: auto;
        margin-left: auto;
        margin-right: .75rem;
        grid-column: auto;
    }
}

.the-booking-form__text {
    grid-row: 2;
    grid-column: first/right-side-gutter;
    text-align: center;
    margin-top: 1.25rem;
    font-size: .875rem;

    @media @q-md-min {
        margin-top: 2rem;
        font-size: 1rem;
    }
}

.the-booking-form__text--error {
    color: var(--color-highlight-error);
}
</style>
